.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-family: "Encode Sans", sans-serif;*/
  font-family: "Lora", sans-serif;
  color: #45658D;
}

body {
  /*font-family: "Encode Sans", sans-serif;*/
  font-family: "Lora", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;
  overflow-x: hidden;
  color: #45658D;
}

.bodyContainer {
  min-height: calc(100vh - 0px);
}

p {
  /*font-family: "Encode Sans", sans-serif;*/
  font-family: "Lora", sans-serif;
  /*color: #707070;*/
  color: #45658D;
  font-size: 1.25rem;
  line-height: 2.5rem;
  text-align: justify;
}

.home {
  text-align: justify;
}

a {
  /*color: #37baed;*/
  font-family: "Montserrat", sans-serif;
  color: #45658D;
  text-decoration: none;
}

main {
  margin-top: 0px;
}

h1.sectionName {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
}

li {
  font-size: 1.2rem;
  /*color: #707070;*/
  color: #45658D;
}

@media (max-width: 991px) {
  main {
    margin-top: 0px;
  }

  p {
    font-size: 1rem;
    line-height: 2rem;
  }
}

.image-editor {
  width: 100%;
  height: auto !important;
}

/* -=-=-=-=-=-=-=- NAVBAR -=-==-=-=-=-=-=-=-*/
.navBrand {
  /*  position: absolute; */
  width: 100%;

  background-color: white;
  display: flex;
  justify-content: center; /* Centra la imagen horizontalmente */
  align-items: center; /* Centra la imagen verticalmente */
  /*   overflow: hidden;  */
}

.navBrand img {
  width: 100%; /* La imagen ocupará todo el ancho del contenedor */
  height: auto; /* Mantiene la proporción de aspecto */
}

@media (max-width: 991px) {
  .navBrand {
    /* width: 600px; */
    /* height: 42px; */
    left: -110px;
  }

  nav#myNavbar {
    top: 0px !important;
    overflow-x: hidden;
  }

  .navbar-nav {
    margin-top: 15px !important;
    border-top: 1px solid #fff;
  }
}

@media (max-width: 767px) {
  .navBrand h1 {
    font-size: 15px;
    margin-top: 25px;
    z-index: 1500;
    position: relative;
    text-align: left;
    color: white;
    width: calc(100% - 80px);
  }
}

.navbar-toggler {
  margin-left: auto;
}

nav#myNavbar {
  height: auto;
  /*background-color: #37bbed;*/
  background-color: #242C4F;
  top: 0px;
}

a.navbar-brand {
  height: 56px;
  padding-top: 3px;
  padding-bottom: 3px;
}

a.nav-link {
  /*font-family: "Encode Sans", sans-serif;*/
  font-family: "Montserrat", sans-serif;
  color: #fff !important;
  text-transform: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1rem !important;
  text-align: center;
}

a.nav-link.active {
  opacity: 0.5;
}

.nav-link:focus,
.nav-link:hover {
  color: #ffffff59;
}

li.nav-item.btn {
  padding: 0px;
  margin-top: 2px;
  transition: 0.4s;
}

li.nav-item.btn a.nav-link:hover {
  opacity: 0.6;
  transition: 0.4s;
}

li.nav-item.btn a.nav-link {
  background-color: #fff;
  border-radius: 50rem;
  padding: 0.35rem 1.5rem !important;
  /*color: #37baed;*/
  color: #45658D;
}

li.nav-item.dropdown.language {
  background-color: #f4f4f4;
  padding: 0px !important;
  margin-left: 30px;
  border-radius: 4px;
  height: 35px;
  margin-top: 4px;
}

li.nav-item.dropdown.language .nav-link {
  text-transform: uppercase;
  font-weight: 400;
  padding: 0.45rem 1.25rem !important;
  font-size: 13px;
}

.dropdown-toggle::after {
  color: #a5a5a5;
  margin-left: 10px;
}

@media (max-width: 1099px) {
  a.nav-link {
    font-size: 1rem;
    padding: 0.5rem 1rem !important;
  }
}

/* -=-=-=-=-=-=-=- MOBILE MENU -=-==-=-=-=-=-=-=-*/
.animated-hamburger {
  width: 30px !important;
  height: 20px !important;
  position: relative !important;
  margin: 0px !important;
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  -webkit-transition: 0.5s ease-in-out !important;
  -moz-transition: 0.5s ease-in-out !important;
  -o-transition: 0.5s ease-in-out !important;
  transition: 0.5s ease-in-out !important;
  cursor: pointer !important;
  top: -4px;
}

.animated-hamburger span {
  display: block !important;
  position: absolute !important;
  height: 2px !important;
  width: 100% !important;
  border-radius: 9px !important;
  opacity: 1 !important;
  left: 0 !important;
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  -webkit-transition: 0.25s ease-in-out !important;
  -moz-transition: 0.25s ease-in-out !important;
  -o-transition: 0.25s ease-in-out !important;
  transition: 0.25s ease-in-out !important;
}

.animated-hamburger span {
  background: #fff !important;
}

.animated-hamburger span:nth-child(1) {
  top: 0px !important;
  -webkit-transform-origin: left center !important;
  -moz-transform-origin: left center !important;
  -o-transform-origin: left center !important;
  transform-origin: left center !important;
  width: 50% !important;
}

.animated-hamburger span:nth-child(2) {
  top: 10px !important;
  -webkit-transform-origin: left center !important;
  -moz-transform-origin: left center !important;
  -o-transform-origin: left center !important;
  transform-origin: left center !important;
}

.animated-hamburger span:nth-child(3) {
  top: 20px !important;
  -webkit-transform-origin: left center !important;
  -moz-transform-origin: left center !important;
  -o-transform-origin: left center !important;
  transform-origin: left bottom !important;
  width: 50% !important;
  margin-left: 50%;
}

.animated-hamburger.open span:nth-child(1) {
  -webkit-transform: rotate(45deg) !important;
  -moz-transform: rotate(45deg) !important;
  -o-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
}

.animated-hamburger.open span:nth-child(2) {
  width: 100% !important;
  opacity: 0 !important;
  transform: translateX(30px) !important;
}

.animated-hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg) !important;
  -moz-transform: rotate(-45deg) !important;
  -o-transform: rotate(-45deg) !important;
  transform: rotate(-45deg) !important;
  top: 21px !important;
  left: 0px !important;
  width: 100% !important;
  margin-left: 0px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

/*-=-=-=-=-=-=-=-=-=- TRABAJOS -=-=-=-=-=-=-=-=-=-=-*/
a.edLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: 0.4s;
}

.icon {
  width: 120px;
  height: 120px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 55%;
  border-radius: 50rem;
  margin-top: 20px;
  transition: 0.4s;
}

a.edLink h1 {
  text-align: center;
  font-size: 1.15rem;
  margin-top: 20px;
  /*color: #707070;*/
  color: #45658D;
  font-weight: 600;
  padding: 0px 20px;
  transition: 0.4s;
}

.inicial {
  background-image: url("assests/icons/semilla.png");
  background-color: #ffd100;
}
.primaria {
  background-image: url("assests/icons/planta.png");
  background-color: #f7941e;
}
.secundaria {
  background-image: url("assests/icons/planta-1.png");
  background-color: #d7df23;
}
.superior {
  background-image: url("assests/icons/arbol.png");
  background-color: #37bbed;
}
.internacional {
  background-image: url("assests/icons/el-planeta-tierra.png");
  background-color: #9283be;
}
.todos {
  background-image: url("assests/icons/planta-en-crecimiento.png");
  background-color: #ee3d8f;
}

a:hover.edLink .icon {
  opacity: 0.5;
  transform: scale(1.1);
  transition: 0.4s;
}

a:hover.edLink h1 {
  color: #424749;
  transition: 0.4s;
}
.titleTwo{
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: relative;
  display: grid;
  margin-top: 40px;
}

/* -=-=-=-=-=-=-=- CARDS -=-==-=-=-=-=-=-=-*/
.datails-container{
  background-color: #DCDCDC;
}

/* - Card convocatorias - */
a .card.convocatorias {
  /*color: #707070;*/
  color: #45658D;
  transition: 0.4s;
  height: 100%;
  border: 1px solid #dee2e6;
  border-radius: 15px;
  overflow: hidden;
}

a:hover .card.convocatorias {
  /*color: #707070;*/
  color: #45658D;
  transition: 0.4s;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
}

.card.convocatorias .card-header {
  /*background-color: #707070;*/
  background-color: #45658D;
}

.card.convocatorias .card-header h1 {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 0px;
}
.card-body{
  padding-left: 4rem;
}

a:hover .card.convocatorias .card-body {
  background-color: #f7f7f7;
  transition: 0.4s;
}

.card.convocatorias .card-body {
  transition: 0.4s;
}

.card.convocatorias .card-body h1 {
  /*color: #707070;*/
  color: #45658D;
  font-size: 1.15rem;
  line-height: 1.25rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.card.convocatorias .card-body p {
  /*color: #707070;*/
  color: #45658D;
  font-size: 0.9rem;
  line-height: 1.35rem;
  margin-bottom: 0px;
}

.card.convocatorias .card-body p.horarios {
  /*color: #707070;*/
  color: #45658D;
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 10px;
  border-top: 1px solid #dee2e5;
  padding-top: 10px;
  margin-bottom: 0px;
}

/* - Card ferias - */
a .card.ferias {
  /*color: #707070;*/
  color: #45658D;
  transition: 0.4s;
  height: 100%;
  border: 1px solid #dee2e6;
  border-radius: 15px;
  overflow: hidden;
}

a:hover .card.ferias {
  /*color: #707070;*/
  color: #45658D;
  transition: 0.4s;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
}

a .card.ferias .card-header .card-img {
  transition: 0.4s;
}

a:hover .card.ferias .card-header .card-img {
  transform: scale(1.15);
  opacity: 0.75;
  transition: 0.4s;
}

.card.ferias .card-header {
  background-color: transparent;
  padding: 0px;
  overflow: hidden;
}

.card.ferias .card-header h1 {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 0px;
}

a:hover .card.ferias .card-body {
  background-color: #f7f7f7;
  transition: 0.4s;
}

a .card.ferias .card-footer {
  background-color: transparent;
  border: none;
  padding: 0px;
}

a .card.ferias .card-footer .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  border-radius: 0px;
}

a:hover .card.ferias .card-footer .btn-secondary {
  background-color: #343131;
  border-color: #343131;
}

.card.ferias .card-body {
  transition: 0.4s;
}

.card.ferias .card-body h1 {
  /*color: #707070;*/
  color: #45658D;
  font-size: 1.15rem;
  line-height: 1.25rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.card.ferias .card-body p {
  /*color: #707070;*/
  color: #45658D;
  font-size: 0.9rem;
  line-height: 1.35rem;
  margin-bottom: 0px;
}

.flagIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 15px;
  background-repeat: no-repeat;
  margin-top: -37px;
  border-radius: 50rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  border: 2px solid white;
}
.carrousel {
  margin-top: 30px;
  width: 100%; /* Ancho deseado */
  overflow: hidden;
}
.carrousel div img {
  width: 100%; /* Asegurar que las imágenes se ajusten al ancho del contenedor */
  height: 500px; /* Asegurar que las imágenes se ajusten a la altura del contenedor */
  object-fit: contain;
}
.argentina {
  background-image: url("./assests/icons/flag-argentina.svg");
}
.chile {
  background-image: url("./assests/icons/flag-chile.svg");
}
.peru {
  background-image: url("./assests/icons/flag-peru.svg");
}

/* - Card trabajos - detalle - */
.card.card-trabajos-detalle {
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  margin-top: 5px;
}

ul.detalleLista {
  list-style: none;
  padding: 0px;
}

.detalleLista li{
  display: flex;
  align-items: baseline;
}

p.trabDetaLabel {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 15px;
}

div .trabDetaText{
  padding-top: 10px;
}
p.trabDetaText {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0px;
 margin-left: 6px;
  padding-bottom: 14px;
}

p.trabDetaText:first-letter {
  text-transform: uppercase;
}

p.trabajoTexto {
  font-size: 1rem;
  line-height: 1.5rem;
}

.ytVidContainer {
  border-radius: 20px;
  overflow: hidden;
}
.titleFormJobs {
  position: relative;
    display: grid;
    justify-content: center;

}
.titleFormJobs h1 {
  position: relative;
  display: flex;
  font-size: 22px;
  font-weight: bold;
}

.formJobs {
  position: relative;
  max-width: 700px;


}

.custom-tooltip {
  /*background-color: #37BBED !important;*/
  background-color: #242C4F !important;
}

.carousel-item{
  width: 33% !important;
}



/* -=-=-=-=-=-=-=- PAGINATION -=-==-=-=-=-=-=-=-*/
ul.pagination.myCards {
  justify-content: center;
  margin-bottom: 0px;
}

ul.pagination.myCards .page-item {
  padding: 8px;
}

ul.pagination.myCards .page-item a.page-link {
  padding: 10px 20px;
  border-radius: 8px;
  color: #6c757d;
  /*font-family: "Encode Sans", sans-serif;*/
  font-family: "Lora", sans-serif;
}

ul.pagination.myCards li.page-item.active a.page-link {
  /*background-color: #37baed;*/
  background-color: #45658D;
  /*border-color: #37baed;*/
  border-color: #45658D;
  color: white !important;
}

.page-item:last-child .page-link, .page-item:nth-last-child(2) .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  height: 30px;
  width: 20px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
}

.page-item:last-child .page-link i, .page-item:nth-last-child(2) .page-link i {
  margin-top: -4px;
}

.page-item:first-child .page-link, .page-item:nth-of-type(2) .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  height: 30px;
  width: 20px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
}

.page-item:first-child .page-link i, .page-item:nth-of-type(2) .page-link i {
  margin-top: -4px;
}

.pages-showing {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 10px;
  color: #8d9399;
  font-size: 1.15rem;
}

/*-=-=-=-=-=-=-=-=-=- FOOTER -=-=-=-=-=-=-=-=-=-=-*/
footer {
  /*background-color: #e3e4e3;*/
  background-color: #242C4F;
  min-height: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

footer h6 {
  margin-top: -15px;
}

img.footerLogo {
  height: 180px;
}

.si-container {
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 80%;
}

.si-container a img {
  height: 50px;
  transition: 0.4s;
}

.si-container a:hover img {
  opacity: 0.5;
  transition: 0.4s;
}

.btn-custom {
  max-width: 225px;
  width: 100%
}


@media (max-width: 768px) {
  footer {
    min-height: 220px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img.footerLogo {
    height: 158px;
    margin-top: 35px;
  }

  .bandera {
    height: 30px !important;
    margin-top: 35px !important;
  }

  footer h6 {
    margin-top: 0px;
    text-align: center;
  }

  .si-container {
    max-width: 100%;
    justify-content: center;
  }

  .si-container a img {
    height: 40px;
    transition: 0.4s;
  }
}
/*-=-=-=-=-=-=-=-=-=-Distinction -=-=-=-=-=-=-=-=-=-=-*/
.distinction{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  height: 100vh;
}
.linkDistiction{
  font-weight: 500;
}
.liDistiction{
  padding-bottom: 8px;
}

/*-=-=-=-=-=-=-=-=-=- CAROUSEL-=-=-=-=-=-=-=-=-=-=-*/

.slick-dots {
  display: none !important;
}


/* .slick-prev:before {
  content: '' !important;
} */

/* .slick-prev {
  position: absolute !important;
  top: 50% !important;
  left: 10px !important; 
  transform: translate(0, -50%) !important;
  width: 0 !important;
  height: 0 !important;
  border-style: solid !important;
  border-width: 10px 12px 10px 0 !important;
  border-color: transparent #707070 transparent transparent !important;
  cursor: pointer !important;
}  */

.cardJobtwo{
  position: relative;
  display: flex;
  justify-content: center;
}
/* Estilos para el contenedor del carousel */
.carousel-container {
  position: relative;
  text-align: center;
}

/* Estilo para el botón "prev" */
.custom-prev-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 1;
  cursor: pointer;
}

/* Estilo para el botón "next" */
.custom-next-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  cursor: pointer;
}



.carousel .control-next.control-arrow:before {
  border-left: 21px solid #707070 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 21px solid #707070 !important;
}

.carousel .thumbs {
  display: none !important;
}
.carousel .thumbs-wrapper {
  display: none !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 19px solid transparent !important;
  border-bottom: 19px solid transparent !important;
  content: "";
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: inherit !important;
}

.control-prev,
.control-next {
  background-color: transparent !important;
}

.cardJobtwo-div{
  width: 50%;
}
.cardJobtwo-div-one{
  margin-left: 15px;
  margin-right: 10px;
}
.cardJobtwo-div-two{
  margin-left: 20px;
}
/*-=-=-=-=-=-=-=-=-=- FORMS -=-=-=-=-=-=-=-=-=-=-*/
.btn-search {
  border-radius: 8px;
}

.form-select.custom-select {
  border-radius: 8px;
}

.form-control.custom-form {
  border-radius: 8px;
}

.page-link.active,
.active > .page-link {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.page-item:not(.active) .page-link {
  color: #6c757d;
}

.page-item:not(.active) .page-link:hover:not(.arrow) {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #dee2e6 !important;
  background-color: #343131 !important;
  color: #ffffff;
}

.page-link:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.25);
}

/*-=-=-=-=-=-=-=-=-=- HOME -=-=-=-=-=-=-=-=-=-=-*/
.homeTitle {
  font-size: 2rem;
}

.form-label{
  font-size: 14px;
  font-weight: bold;
  /*color: rgb(112, 112, 112);*/
  color: #45658D;
}

.custom-margin {
  width: 80%;
  margin: auto;
  margin-top: 100px !important;
}

@media (max-width: 768px) {
  .custom-div-form{
    display: grid !important;
  }
  .custom-with{
    width: 100% !important;
    margin-top: 15px !important;
  }
  
  .mobile{
    flex-direction: column;
  }
  .cardJobtwo-div-one{
    margin-left: 0px;
    margin-right: 0px;
  }
  .cardJobtwo-div-two{
    margin-left: 0px;
  }
  .cardJobtwo-div{
    width: 100%;
  }
  .cardJobtwo{
    flex-direction: column !important;
  }
  .homeTitle {
    font-size: 1.3rem;
  }

  .subtitleHome {
    font-size: 1rem;
  }

  .homeLocation {
    font-size: 0.9rem;
  }

  .endTitle {
    font-size: 0.5rem !important;
  }

  ._homeText {
    font-size: 0.6rem;
    color: #45658D;
  }
}
